<template>
    <!--展览详情-->
    <div class="page home exhibitionDetail">
        <div class="plateBox">
            <div class="flex_b_t">
                <div class="plateTit flex_l_c"><span
                        class="plateTitIcon mainBgColor"></span><span>展览详情</span></div>
                <div>
                    <el-button type="primary" v-show="item1.linkUrl" v-for="(item1, index1) in this.sysExhibitionLinks"
                               :key="index1" size="small" plain @click="webpage(item1)">{{item1.linkName}}
                    </el-button>
                    <!-- <el-button type="primary" size="small" plain @click="hall">数字展厅</el-button> -->
                    <el-button type="primary" size="small" plain @click="back">返回</el-button>
                </div>
            </div>
            <div class="fieldBox">
                <div class="exhibitionNameItem item">
                    <div class="flex_l_t">
                        <div class="field">展览名称：</div>
                        <div class="flex1">{{inputForm.exhibitionName}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览编号：</div>
                        <div class="flex1">{{inputForm.exhibitionId}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览类型：</div>
                        <div class="flex1"
                             v-for="item in $dictUtils.getDictList('exhibition_type')"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" v-show="item.value==inputForm.exhibitionTypeValue">
                            {{item.value==inputForm.exhibitionTypeValue?item.label:""}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">主办方：</div>
                        <div class="flex1">{{inputForm.efieldName15}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">策展人：</div>
                        <div class="flex1">{{inputForm.exhibitionUserName}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览时间：</div>
                        <div class="flex1">{{inputForm.beginDatetime}}~{{inputForm.endDatetime}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">主题词：</div>
                        <div class="flex1">{{inputForm.efieldName13}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plateBox retrievedResult">
            <div class="flex_l_c tab b_b">
                <div :class="['tabItem flex_c_c', tabIndex == index ? 'mainBdColor mainColor': 'resetColor resetBdColor']"
                     v-for="(item, index) in tabArr" :key="index" @click="tabChange(index)">
                    <span :class="['iconfont', item.icon, tabIndex == index ? 'mainColor': 'resetIconColor']"></span>
                    <span>{{item.name}}</span>
                </div>
            </div>
            <!--展览属性-->
            <div class="fieldBox" v-show="tabIndex == 0">
                <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>展览属性</span></div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览编号：</div>
                        <div class="flex1">{{inputForm.exhibitionId}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览类型：</div>
                        <div class="flex1" v-for="item in $dictUtils.getDictList('exhibition_type')"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" v-show="item.value==inputForm.exhibitionTypeValue">
                            {{item.value==inputForm.exhibitionTypeValue?item.label:""}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">主办方：</div>
                        <div class="flex1">{{inputForm.efieldName15}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">策展人：</div>
                        <div class="flex1">{{inputForm.exhibitionUserName}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览时间：</div>
                        <div class="flex1">{{inputForm.beginDatetime}}~{{inputForm.endDatetime}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">主题词：</div>
                        <div class="flex1">{{inputForm.efieldName13}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览规模：</div>
                        <div class="flex1"
                             v-for="item in $dictUtils.getDictList('exhibition_scale')"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" v-show="item.value==inputForm.exhibitionScaleValue">
                            {{item.value==inputForm.exhibitionScaleValue?item.label:""}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览地区：</div>
                        <div class="flex1"
                             v-for="item in $dictUtils.getDictList('exhibition_region')"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" v-show="item.value==inputForm.exhibitionAreaValue">
                            {{item.value==inputForm.exhibitionAreaValue?item.label:""}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览地点：</div>
                        <div class="flex1">{{inputForm.exhibitionPlaceValue}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">档案号：</div>
                        <div class="flex1">{{inputForm.efieldName1}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">保管期限：</div>
                        <div class="flex1">{{inputForm.efieldName2}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">存放位置：</div>
                        <div class="flex1">{{inputForm.efieldName3}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">立卷单位：</div>
                        <div class="flex1">{{inputForm.efieldName4}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">总页数：</div>
                        <div class="flex1">{{inputForm.efieldName5}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">总件数：</div>
                        <div class="flex1">{{inputForm.efieldName6}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">总盒数：</div>
                        <div class="flex1">{{inputForm.efieldName7}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">起始盒号：</div>
                        <div class="flex1">{{inputForm.efieldName8}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">截止盒号：</div>
                        <div class="flex1">{{inputForm.efieldName9}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">密级：</div>
                        <div class="flex1">{{inputForm.efieldName10}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">策划部门：</div>
                        <div class="flex1">{{inputForm.efieldName11}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">档案馆号：</div>
                        <div class="flex1">{{inputForm.efieldName12}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">档案馆代码：</div>
                        <div class="flex1">{{inputForm.efieldName14}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览主题：</div>
                        <div class="flex1">{{inputForm.exhibitionTheme}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览年代：</div>
                        <div class="flex1">{{inputForm.exhibitionAge}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览主题类型：</div>
                        <div class="flex1">{{inputForm.exhibitionThemeType}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展览主题人物：</div>
                        <div class="flex1">{{inputForm.exhibitionSubject}}</div>
                    </div>
                </div>
            </div>
            <!--档案-->
            <div class="fieldBox" v-show="tabIndex == 1">
                <div class="flex_b_t">
                    <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>档案资源</span></div>
                    <el-button-group>
                        <el-button size="small" :type="showRecordList ? '' : 'primary'" @click="showRecordList = false">
                            卡片
                        </el-button>
                        <el-button size="small" :type="showRecordList ? 'primary' : ''" @click="showRecordList = true">
                            列表
                        </el-button>
                    </el-button-group>
                </div>
                <el-tabs v-model="catalogueTabVal" type="card" @tab-click="catalogueChange">
                    <el-tab-pane lazy v-for="(item, index) in catalogueTabs" :key="item.id" :label="item.title"
                                 :name="item.id">
                        <div class="noData" v-if="recordList.length == 0 && !showRecordList">暂无数据</div>
                        <!--卡片-->
                        <div class="recordItemBox" v-if="!showRecordList && recordList.length > 0">
                            <div class="recordItem pointer" v-for="(item, index) in recordList" @click="view(item)"
                                 :key="index">
                                <el-image v-if="item.fileFormat == 'docx' || item.fileFormat == 'doc'" fit="contain"
                                          :src="require('@/assets/img/word.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'rar' || item.fileFormat == 'zip'" fit="contain"
                                          :src="require('@/assets/img/zip.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'pdf'" fit="contain"
                                          :src="require('@/assets/img/pdf.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'ppt' || item.fileFormat == 'pptx'"
                                          fit="contain" :src="require('@/assets/img/ppt.png')"
                                          class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'xls' || item.fileFormat == 'xlsx'"
                                          fit="contain" :src="require('@/assets/img/excel.png')"
                                          class="itemImg"></el-image>
                                <el-image
                                        v-else-if="item.fileFormat == 'psd' || item.fileFormat == 'tif' || item.fileFormat == 'JPG' || item.fileFormat == 'jpg' || item.fileFormat == 'png' || item.fileFormat == 'PNG' || item.fileFormat == 'jpeg' || item.fileFormat == 'gif'"
                                        fit="contain" :src="require('@/assets/img/img.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'mp3'" fit="contain"
                                          :src="require('@/assets/img/audio.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'mp4' || item.fileFormat == 'mov'" fit="contain"
                                          :src="require('@/assets/img/video.png')" class="itemImg"></el-image>
                                <el-image v-else-if="item.fileFormat == 'txt'" fit="contain"
                                          :src="require('@/assets/img/text.png')" class="itemImg"></el-image>
                                <el-image v-else fit="contain" :src="require('@/assets/img/file.png')"
                                          class="itemImg"></el-image>
                                <el-tooltip effect="dark" :content="item.fileName" placement="top">
                                    <div class="info omit text_center">{{item.fileName}}</div>
                                </el-tooltip>
                            </div>
                        </div>
                        <!--列表-->
                        <el-table v-show="showRecordList"
                                  :data="recordList" v-loading="loading"
                                  @selection-change="selectionChangeHandle"
                                  class="table" ref="tableBox">
                            <el-table-column type="selection" width="50" fixed/>
                            <el-table-column v-for="(item,index) in config" :key="item.id" :prop="item.fieldEnname"
                                             :label="item.recordName"
                                             show-overflow-tooltip v-if="item.onShow==0">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="item.fieldEnname=='fileFormat'">
                                        {{scope.row[item.fieldEnname]}}
                                    </el-tag>
                                    <div v-else>{{scope.row[item.fieldEnname]}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="view(scope.row)">查看</el-button>
                                    <el-button type="text" @click="download(scope.row)">下载</el-button>
                                    <el-button type="text" @click="collect(scope.row.id, 1)" v-if="!scope.row.collectionId">收藏</el-button>
                                    <el-button type="text" v-else @click="cancelCollect(scope.row.collectionId)">取消收藏</el-button>
                                    <el-button type="text" @click="borrow(scope.row,1)">借阅</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!--文物-->
            <div class="culturalBox" v-show="tabIndex == 2">
                <div class="flex_b_t">
                    <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>文物资源</span></div>
                    <div>
                        <el-button-group class="m_l1">
                            <el-button size="small" :type="showCulturalList ? '' : 'primary'"
                                       @click="showCulturalList = false">卡片
                            </el-button>
                            <el-button size="small" :type="showCulturalList ? 'primary' : ''"
                                       @click="showCulturalList = true">列表
                            </el-button>
                        </el-button-group>
                    </div>
                </div>
                <div class="noData" v-if="culturalList.length == 0 && !showCulturalList">暂无数据</div>
                <!--卡片-->
                <div class="listBox" v-if="!showCulturalList && culturalList.length > 0">
                    <div class="item pointer" v-for="(item, index) in culturalList" :key="index" @click="details(item)">
                        <el-image fit="cover" :src="item.url" class="itemImg">
                            <el-image slot="placeholder" fit="cover" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')"></el-image>
                            <el-image slot="error" fit="cover" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')"></el-image>
                        </el-image>
                        <div class="info">
                            <el-tooltip effect="dark" :content="item.culturalName" placement="top">
                                <div class="name omit">{{item.culturalName}}</div>
                            </el-tooltip>
                            <div class="flex_b_t">
                                <el-tooltip effect="dark" :content="item.times" placement="top" :disabled="!item.times">
                                    <div class="age omit" v-show="item.times">{{item.times}}</div>
                                </el-tooltip>
                                <el-tooltip effect="dark" :content="item.size" placement="top" :disabled="!item.size">
                                    <div class="age omit" v-show="item.size">{{item.size}}</div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <!--列表-->
                <el-table v-show="showCulturalList"
                          :data="culturalList" v-loading="culturalLoading"
                          class="table" ref="tableBox1">
                    <el-table-column type="selection" width="50" fixed/>
                    <el-table-column v-for="(item,index) in culturalConfig" :key="item.id" :prop="item.fieldEnname"
                                     :label="item.recordName"
                                     show-overflow-tooltip v-if="item.onShow==0">
                        <template slot-scope="scope">
                            <!--<el-input-number v-if="item.fieldEnname=='sequenceNumber'" style="width: 100px" size="small" v-model="scope.row.sequenceNumber" @change="handleChangeOrder(scope.row)" :min="1" ></el-input-number>-->
                            <el-image v-if="item.fieldEnname=='url'"
                                      style="width: 60px; height: 60px"
                                      :src="scope.row.url"
                                      fit="contain"></el-image>
                            <div v-else>{{scope.row[item.fieldEnname]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button size="small" type="text" @click="details(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="margin-bottom:15px"
                               @size-change="culturalSizeChangeHandle"
                               @current-change="culturalCurrentChangeHandle"
                               :current-page="culturalPageNo"
                               :page-sizes="[10, 20, 50, 100]"
                               :page-size="culturalPageSize"
                               :total="culturalTotal"
                               background
                               layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!--展览团队成员-->
            <div class="memberBox" v-show="tabIndex == 3">
                <div class="text_right">
                    <el-button-group class="m_l1">
                        <el-button size="small" :type="showTeamList ? '' : 'primary'" @click="clickCard(1)">卡片
                        </el-button>
                        <el-button size="small" :type="showTeamList ? 'primary' : ''" @click="clickCard(2)">列表
                        </el-button>
                    </el-button-group>
                </div>
                <div class="noData" v-if="exhibitionTeamList.length == 0 && !showTeamList">暂无数据</div>
                <!--卡片-->
                <div class="listBox" v-if="!showTeamList && exhibitionTeamList.length > 0">
                    <div v-for="(item1, index) in exhibitionTeamList" :key="index">
                        <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>{{item1.name}}</span></div>
                        <div class="member" :style="{background : memberId == item.id ? defaultTheme: '#ffffff', color : memberId == item.id ? '#ffffff' : '#000000', boxShadow : memberId == item.id ? `0rem 0rem 1rem 0rem ${defaultTheme}` : '0rem 0rem 1rem 0rem #BEBEBE' }"
                             v-for="(item, index) in item1.teamMemberList" :key="index" @click="viewMember('view', item)"
                             @mouseenter="memberEnter(item)" @mouseleave="memberLeave">
                            <img src="@/assets/img/selMemberBg.png" class="noSelMemberBg" v-if="memberId == item.id">
                            <img src="@/assets/img/noSelMemberBg.png" class="noSelMemberBg" v-else>
                            <el-tooltip effect="dark" :content="item.name" placement="top">
                                <div class="omit name">{{item.name}}</div>
                            </el-tooltip>
                        </div>
                    </div>
                    <el-pagination style="margin-bottom:15px"
                                   @size-change="teamSizeChangeHandle"
                                   @current-change="teamCurrentChangeHandle"
                                   :current-page="exhibitionTeamPageNo"
                                   :page-sizes="[10, 20, 50, 100]"
                                   :page-size="exhibitionTeamPageSize"
                                   :total="exhibitionTeamTotal"
                                   background
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
                <!--列表-->
                <div v-show="showTeamList">
                    <el-table :data="teamList" v-loading="teamLoading" class="table">
                        <el-table-column prop="serialNumber" label="序号" width="110" sortable></el-table-column>
                        <el-table-column prop="exhibitionTeamName" label="展览组织" width="120" sortable show-overflow-tooltip/>
                        <el-table-column prop="name" label="姓名(名称）" width="120" sortable show-overflow-tooltip/>
                        <el-table-column prop="gender" label="性别" sortable show-overflow-tooltip/>
                        <el-table-column prop="dateOfBirth" label="出生日期" width="120" :formatter="formatterBirth" sortable show-overflow-tooltip/>
                        <el-table-column prop="position" label="职务" sortable show-overflow-tooltip/>
                        <el-table-column prop="theTitle" label="职称" sortable show-overflow-tooltip/>
                        <el-table-column prop="professional" label="专业" sortable show-overflow-tooltip/>
                        <el-table-column prop="specialty" label="专长" sortable show-overflow-tooltip/>
                        <el-table-column prop="national" label="民族" sortable show-overflow-tooltip/>
                        <el-table-column prop="educationBackground" label="学历" sortable show-overflow-tooltip/>
                        <el-table-column prop="phone" label="联系电话" width="120" sortable show-overflow-tooltip/>
                        <el-table-column prop="contribution" label="承担的工作（贡献）" width="180" sortable show-overflow-tooltip/>
                    </el-table>
                    <el-pagination style="margin-bottom:15px"
                                   @size-change="memberSizeChangeHandle"
                                   @current-change="memberCurrentChangeHandle"
                                   :current-page="exhibitionMemberPageNo"
                                   :page-sizes="[10, 20, 50, 100]"
                                   :page-size="exhibitionMemberPageSize"
                                   :total="exhibitionMemberTotal"
                                   background
                                   layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--借阅-->
        <BorrowForm ref="borrowForm"></BorrowForm>
        <!-- 文物详情 -->
        <DetailPage ref="detailPage" @refreshDataList="tableData('')"></DetailPage>
        <!--        <FileDetails ref="fileDetails" @refreshDataList="getFileList"></FileDetails>-->
        <!-- 档案详情 -->
        <FileDetails1 ref="fileDetails1" @refreshDataList="getFileList"></FileDetails1>
        <!--团队成员详情-->
        <AddMember ref="addMember"></AddMember>
    </div>
</template>

<script>
    import BorrowForm from '../search/BorrowForm' // 借阅弹窗
    import FileDetails from '../dataCollection/cultural/fileDetails' // 详情
    import DetailPage from '../search/detailPage' // 查看弹窗
    import FileDetails1 from '../fileManagement/fileManagement/fileDetails' // 详情
    import AddMember from '../dataCollection/team/addMember' // 详情
    export default {
        components: {BorrowForm, FileDetails, DetailPage, FileDetails1, AddMember},
        data() {
            return {
                inputForm: {
                    id: '',
                    exhibitionId: '',
                    exhibitionName: '',
                    exhibitionTypeValue: '',
                    exhibitionScaleValue: '',
                    exhibitionAreaValue: '',
                    exhibitionPlaceValue: '',
                    beginDatetime: '',
                    endDatetime: '',
                    exhibitionTheme: '',
                    exhibitionAge: '',
                    exhibitionThemeType: '',
                    exhibitionSubject: '',
                    exhibitionUserName: '',
                    efieldName1: "",
                    efieldName2: "",
                    efieldName3: "",
                    efieldName4: "",
                    efieldName5: "",
                    efieldName6: "",
                    efieldName7: "",
                    efieldName8: "",
                    efieldName9: "",
                    efieldName10: "",
                    efieldName11: "",
                    efieldName12: "",
                    efieldName13: "",
                    efieldName14: "",
                    efieldName15: "",
                },
                config: [],
                tabIndex: 0,
                tabArr: [
                    {
                        name: '展览属性',
                        icon: 'icon-zhanlan'
                    },
                    {
                        name: '档案',
                        icon: 'icon-dangan'
                    },
                    {
                        name: '文物',
                        icon: 'icon-wenwu-mian'
                    },
                    {
                        name: '展览团队成员',
                        icon: 'icon-chengyuan'
                    }
                ],
                catalogueTabVal: '0',
                catalogueTabs: [
                    {
                        id: '0',
                        title: '全部',
                    }
                ],
                showRecordList: false,
                loading: false,
                record1: {
                    id: '',
                    title: ''
                },
                recordList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                // 文物
                showCulturalList: false,
                culturalLoading: false,
                culturalList: [],
                sysExhibitionLinks: [],
                culturalPageNo: 1,
                culturalPageSize: 10,
                culturalTotal: 0,
                culturalConfig: [],
                // 展览团队
                showTeamList: false,
                teamLoading: false,
                teamList: [],
                memberId: null,
                exhibitionTeamList: [],
                exhibitionTeamPageNo: 1,
                exhibitionTeamPageSize: 10,
                exhibitionTeamTotal: 0,
                exhibitionMemberPageNo: 1,
                exhibitionMemberPageSize: 10,
                exhibitionMemberTotal: 0,

                //团队成员tab权限
                permissionsFlag : false,


            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
        },
        mounted() {
            this.inputForm.id = this.$route.query.id
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
            this.init();
            this.querySysOriginalDescriptionAll();
            this.queryCulturalSysOriginalDescriptionAll();
            this.getLinkList();
            this.getRecordList();
            let dictList = JSON.parse(sessionStorage.getItem('allMenuList') || '[]')
            dictList.forEach(v=>{
                if(v.name=="展览团队成员"){
                    this.permissionsFlag =true;
                }
            })
        },
        methods: {
            init() {
                // 获取展览id
                // console.log(this.$route.query.id);
                this.$axios(this.api.zlzs.informationQuery, {id: this.$route.query.id}, 'get').then(data => {
                    if (data && data.status) {
                        this.loading = false
                        this.inputForm = this.recover(this.inputForm, data.data)
                        console.log(this.inputForm);
                    }
                })
            },
            // tab切换
            tabChange(index) {
                this.tabIndex = index;
                if (this.tabIndex == 1) {
                    this.tableData('');
                } else if (this.tabIndex == 2) {
                    this.getFileList();
                } else if (this.tabIndex == 3) {
                    if(this.showTeamList){
                        this.getMember();
                    }else{
                        this.getTeam();
                    }
                }
                this.$nextTick(() => {
                    this.changeMainColor(this.defaultTheme);
                })
            },
            // 目录数据查询
            getRecordList() {
                this.loading = true
                let that = this;
                this.$axios(this.api.zlzs.selectSysDirectory3, {
                    'exhibitionId': this.inputForm.id
                }, 'get').then(data => {
                    if (data && data.status) {
                        data.data.forEach(function (v) {
                            that.record1 = {}
                            // that.$set(that.record1,"id",v.id);
                            // that.$set(that.record1,"name",v.directoryName)
                            that.record1.id = v.id;
                            that.record1.title = v.directoryName;
                            that.catalogueTabs.push(that.record1)
                        })

                        // this.total2 = parseInt(data.data.total)
                        // this.loading = false
                    }
                })
            },
            tableData(directoryId) {
                // if(this.catalogueShuju || this.biaoSelect){
                this.loading = true
                this.$axios(this.api.zlzs.queryAllByLimitRetrieve, {
                    "name": '',
                    "exhibitionId": this.inputForm.id,
                    "directoryId": directoryId,
                    "labelId": '',
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 4,
                    'onDetails': 1,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.recordList = data.data.records
                        let that = this;
                        this.recordList.forEach(function (v) {
                            that.$set(v, "filePath", v.url);
                            // v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.length)
                        })
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.$refs.tableBox.doLayout();
                    }
                })
                // }
            },
            getLinkList() {
                this.$axios(this.api.zlzs.SysExhibitionLinkList, {id: this.inputForm.id}, 'get').then(data => {
                    if (data.data.length > 0 && data.status) {
                        // this.$set(this.inputForm ,"sysExhibitionLinks",data.data)
                        this.sysExhibitionLinks = data.data
                    }
                    console.log("====", this.sysExhibitionLinks)
                })
            },
            // 查询文物清单
            getFileList() {
                if (this.inputForm.id) {
                    this.loading = true
                    this.$axios(this.api.zlzs.culturalRelicsRetrieve, {
                        'exhibitionId': this.inputForm.id,
                        "name": '',
                        "archive": 1,
                        'pageNo': this.culturalPageNo,
                        'pageSize': this.culturalPageSize,
                        'flag': 5,
                        'onDetails': 1,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.culturalList = data.data.records
                            this.culturalTotal = parseInt(data.data.total)
                            this.loading = false
                            this.$refs.tableBox1.doLayout();
                        }
                    })
                }

            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 4,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'quanzongNo') {
                                v.fieldEnname = 'exhibitionName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                        // this.search();
                    }
                })
            },
            queryCulturalSysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 3,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.culturalConfig = data
                        this.culturalConfig.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'createId') {
                                v.fieldEnname = 'createName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                    }
                })
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            // 文物数据查询
            getCulturalList() {

            },
            // 目录切换
            catalogueChange(val) {
                // console.log(val.name);
                var catalog = val.name == 0 ? '' : val.name
                this.pageNo = 1;
                this.tableData(catalog);
            },
            // 展览网页
            webpage(item) {
                window.open(item.linkUrl);
            },
            // 数字展厅
            hall() {

            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 收藏
            collect(id, val) {
                let ids = val == 1 ? id : this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$axios(this.api.zlzs.MyCollectionSave, {
                    fileIds: ids
                }, 'post').then(data => {
                    if (data.status) {
                        this.$message.success("收藏成功")
                    } else {
                        this.$message.error("收藏失败")
                    }
                    this.tableData();
                })
            },
            // 取消收藏
            cancelCollect(collectionId) {
                this.$axios(this.api.zlzs.MyCollectionRemoveById, {
                    ids: collectionId
                }, 'delete').then(data => {
                    if (data.status) {
                        this.$message.success("取消收藏成功")
                    } else {
                        this.$message.error("取消收藏失败")
                    }
                    this.tableData();
                })
            },
            // 借阅
            borrow(item, val) {
                let data = val == 1 ? [item] : this.dataListSelections
                this.$refs.borrowForm.init(data)
            },
            // 下载
            // download(id) {
            //     let ids = id || this.dataListSelections.map(item => {
            //         return item.id
            //     }).join(',')
            // },
            addWatermark(url) {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = this.$getFileTemporary + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                // if (this.filePath.match(RegExp(/home/))) {
                //     sourceFile = url.substring(0, (url.indexOf("/")) + 1) + "home/fileTemporary/" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                // } else {
                //     sourceFile = url.substring(0, (url.indexOf("\\")) + 1) + "fileTemporary\\" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("\\"), url.length)
                // }
                return new Promise((resolve, reject) => {
                    this.$axios("zyd-catalogue/pdf/addWatermark", {
                        "target": url,
                        "sourceFile": sourceFile,
                        "watermark": watermark
                    }, 'post').then(data => {
                        if (data.status) {
                            resolve(data.data)
                        } else {
                            resolve("")
                        }
                    })
                })
            },
            // 下载
            download(row) {
                console.log('================')
                console.log(row.url)
                let api = this.api.zlzs.queryByIdRetrieve
                this.$axios(api, {
                    'id': row.id,
                    'flag': 2,
                    'onDetails': 0
                }, 'get').then(data => {
                    if (data.status) {
                        let log = {
                            operModular: "5",//模块
                            operType: "19",//类型
                            operTerm: row.fileName,//内容
                            operSystem: "1",//结果:失败
                        }
                        const a = document.createElement('a')
                        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                        const url = row.url;  // 完整的url则直接使用
                        // 这里是将url转成blob地址，
                        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                            a.href = URL.createObjectURL(blob)
                            console.log(a.href)
                            a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
                            // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                            document.body.appendChild(a)
                            a.click()
                            log.operSystem = "0"
                            // this.$axios(this.api.zlzs.logSave, log, 'post')
                        })
                    } else {
                        this.$message.error("您暂无下载权限，请提交借阅申请")
                    }

                })
            },
            // 查看
            view(row) {
                this.$refs.detailPage.init(row.id, 'itemRetrieval')
            },
            // 详情
            details(row) {
                console.log(row);
                this.$refs.detailPage.init(row.id, 'culturalList')
                // this.$refs.fileDetails.init("view", row, this.inputForm.id,"management")
            },
            formatterBirth(row) {
                if(row.dateOfBirth) {
                    let date = row.dateOfBirth.split('-')
                    return date[0] + '年' + date[1] + '月'
                }
            },
            // 获取团队数据
            getTeam() {
                this.teamLoading = true
                this.$axios(this.api.zlzs.exhibitionTeamAll, {
                    "name": "",
                    "informationId": this.inputForm.id,
                    'pageNo': this.exhibitionTeamPageNo,
                    'pageSize': this.exhibitionTeamPageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionTeamList = data.data.records
                        this.exhibitionTeamTotal = parseInt(data.data.total)
                        //查询所有
                        // this.exhibitionMemberPageNo = -1
                        // this.getMember();
                    } else {
                        this.$message.error("查询失败")
                    }
                })
            },
            // 查询成员
            getMember() {
                if (this.teamId !== '') {
                    this.loading = true
                    this.$axios(this.api.zlzs.TeamMemberList, {
                        'exhibitionTeamId': this.teamId,
                        'informationId': this.inputForm.id,
                        "name": "",
                        'current': this.exhibitionMemberPageNo,
                        'size': this.exhibitionMemberPageSize,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.teamList = data.data.records
                            this.exhibitionMemberTotal = parseInt(data.data.total)
                            this.teamLoading = false
                            this.teamList.forEach(f => {
                                if(f.gender=="0"){
                                    f.gender ="男";
                                }else if(f.gender=="1"){
                                    f.gender ="女"
                                }
                            })
                            this.loading = false
                        }
                    })
                }
            },
            // 查看成员
            viewMember(method, row) {
                this.memberId = row.id
                this.$refs.addMember.init(method, row)
            },
            memberEnter(row) {
                console.log(row);
                this.memberId = row.id
            },
            memberLeave() {
                this.memberId = null
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.tableData();
            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.tableData();
            },
            // 文物每页数
            culturalSizeChangeHandle(val) {
                this.culturalPageSize = val;
                this.culturalPageNo = 1;
                this.getFileList();
            },
            // 文物当前页
            culturalCurrentChangeHandle(val) {
                this.culturalPageNo = val;
                this.getFileList();
            },
            // 团队每页数
            teamSizeChangeHandle(val) {
                this.exhibitionTeamPageSize = val;
                this.exhibitionTeamPageNo = 1;
                this.getTeam();
            },
            // 团队当前页
            teamCurrentChangeHandle(val) {
                this.exhibitionTeamPageNo = val;
                this.getTeam();
            },
            // 成员每页数
            memberSizeChangeHandle(val) {
                this.exhibitionMemberPageSize = val;
                this.exhibitionMemberPageNo = 1;
                this.getMember();
            },
            // 成员当前页
            memberCurrentChangeHandle(val) {
                this.exhibitionMemberPageNo = val;
                this.getMember();
            },
            clickCard(obj){
                if(obj == 1){
                    this.showTeamList = false
                    this.exhibitionMemberPageNo = -1
                    this.getTeam();
                }else{
                    this.exhibitionMemberPageNo = 1
                    this.getMember();
                    this.showTeamList = true
                }
            },
            // 返回
            back() {
                if (this.$route.query.type == 0) {
                    this.$router.push({path: '/dyyg/home'});
                } else if (this.$route.query.type == 1) {
                    let item = {name: '', id: ''}
                    this.$router.push({path: '/zlzs/retrieval/retrievedResult', query: {val: item, type: 1}});
                } else {
                    this.$router.back()
                }
            },
        }
    }
</script>

<style scoped>

</style>
